import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/header.css';
// import Rules from '../Components/Rules'
import logo from '../assets/logo.png';
const Header = () => {
  return (
    <div className="header_sec">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href={window.location.href}
            //  href="https://intelliatech.com/" 
            // target="_blank" rel="noopener noreferrer"
            >
              <img src={logo} alt="Intelliatech" className="img_logo"/>
            </a>
            <button 
              className="navbar-toggler" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target="#navbarSupportedContent" 
              aria-controls="navbarSupportedContent" 
              aria-expanded="false" 
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">Home</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="#">Daily Challenge</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/rules">Rules</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/awards">Awards</a>
                </li> */}
               
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
